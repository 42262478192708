
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import useVuelidate from "@vuelidate/core";
import { helpers,required } from "@vuelidate/validators";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {
  addPatient,
  deletePatient,
  getPatients,
  updatePatient,
} from "@/api/patient.api";

export interface Data {
  items: [];
  selectedItem?: { id: string; effectiveDate: Date };
  createNewMode: boolean;
}
export default defineComponent({
  name: "PatientsCRUDPage",
  components: {},
  setup() {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      items: [],
      selectedItem: undefined,
      createNewMode: true,
    });

    data.selectedItem = {
      id: "",
      effectiveDate: new Date(),
    };

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Patients");
      await getAll();
    });

    async function switchMode() {
      data.createNewMode = true;
      data.selectedItem = {
        id: "",
        effectiveDate: new Date(),
      };
    }

    async function getAll() {
      data.items = await getPatients();
    }

    async function addItem() {
      const result = await v$.value.$validate();
      if (result) {
        await addPatient(data.selectedItem);
        await getAll();
        data.createNewMode = false;
      }
    }

    function selectItem(item) {
      data.selectedItem = item;
      data.createNewMode = false;
    }

    async function updateItem() {
      const result = await v$.value.$validate();
      if (result) {
        await updatePatient(data.selectedItem);
      }
    }

    async function deleteItem() {
      await deletePatient(data.selectedItem);
      await getAll();
    }

    const patientRules = {
      selectedItem: {
        firstName: { required:helpers.withMessage("Required", required) },
        lastName: { required:helpers.withMessage("Required", required) },
        dob: { required:helpers.withMessage("Required", required) },
        sex: { required:helpers.withMessage("Required", required) },
        address: { required:helpers.withMessage("Required", required) },
        city: { required:helpers.withMessage("Required", required) },
        state: { required:helpers.withMessage("Required", required) },
        zip: { required:helpers.withMessage("Required", required) },
        country: { required:helpers.withMessage("Required", required) },
        email: { required:helpers.withMessage("Required", required) },
      },
    };

    const v$ = useVuelidate(patientRules, data as never);

    return {
      organizationId,
      data,
      addItem,
      selectItem,
      updateItem,
      deleteItem,
      switchMode,
      v$,
    };
  },
});
